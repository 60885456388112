import React, { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonButtons, IonMenuButton, IonRow, IonCol, IonButton, IonList, IonItem, IonLabel, IonInput, IonText } from '@ionic/react';
import './Login.scss';
import { setIsLoggedIn, setUsername, setUserCed, setUserID, setUserOther } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  setUserCed: typeof setUserCed;
  setUserID: typeof setUserID;
  setUserOther: typeof setUserOther;
}

interface LoginProps extends OwnProps,  DispatchProps { }

const Login: React.FC<LoginProps> = ({setIsLoggedIn, history, setUsername: setUsernameAction, setUserCed: setUserCedAction, setUserID: setUserIDAction, setUserOther: setUserOtherAction}) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
   /* if(!username) {
      setUsernameError(true);
    }
    if(!password) {
      setPasswordError(true);
    }*/

    if(/*username &&*/ password) {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({  
          ced: password,
          eventID: '1366',
          client: '2', 
        })
      };
      const response = await fetch('https://www.esimposio.com/miesimposio/app_login/login.php', requestOptions);
      const data = await response.json();

      console.log(data);
      if(data.error){
        alert("Error en el inicio de sesión");
      }
      else{
        await setIsLoggedIn(true);
        await setUsernameAction(data.username);
        await setUserCedAction(data.ced);
        await setUserIDAction(data.id);
        await setUserOtherAction(data.company.concat("&&&",data.role));
        history.push('/tabs/schedule', {direction: 'none'});
      }
      
    }
  };

  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <div className="login-logo">
          <img src="assets/icon/favicon.png" alt="IESTEC LOGO" />
        </div>

        <form noValidate onSubmit={login}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">Identificación</IonLabel>
              <IonInput name="password" type="password" value={password} onIonChange={e => setPassword(e.detail.value!)}>
              </IonInput>
            </IonItem>

            {formSubmitted && passwordError && <IonText color="danger">
              <p className="ion-padding-start">
                Password is required
              </p>
            </IonText>}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">Entrar</IonButton>
            </IonCol>
            {/*<IonCol>
              <IonButton routerLink="/signup" color="light" expand="block">Registrarme</IonButton>
            </IonCol>*/}
          </IonRow>
        </form>

      </IonContent>

    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
    setUserCed,
    setUserID,
    setUserOther
  },
  component: Login
})