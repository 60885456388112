import React, { useEffect, useContext } from 'react';
import { IonRouterContext } from '@ionic/react';
import { Storage } from '@capacitor/storage';

interface RedirectToLoginProps {
  setIsLoggedIn: Function;
  setUsername: Function;
}

const RedirectToLogin: React.FC<RedirectToLoginProps> = ({ setIsLoggedIn, setUsername }) => {
  const ionRouterContext = useContext(IonRouterContext);
  
  const removeData = async () => {
    (await Storage.remove({ key: 'userid' }));
    (await Storage.remove({ key: 'userother' }));
    (await Storage.remove({ key: 'userced' }));
  };
  removeData();
  useEffect(() => {
    setIsLoggedIn(false);
    setUsername(undefined);
    
    ionRouterContext.push('/login')
  }, [setIsLoggedIn, setUsername, ionRouterContext]);
  return null;
};

export default RedirectToLogin;
