// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDJuGvsa_gPgHgpRcuBfr9UmLPp9URRWTg",
  authDomain: "esimposio-imelcf.firebaseapp.com",
  databaseURL: "https://esimposio-imelcf-default-rtdb.firebaseio.com",
  projectId: "esimposio-imelcf",
  storageBucket: "esimposio-imelcf.appspot.com",
  messagingSenderId: "801408805200",
  appId: "1:801408805200:web:06fb73011826e16aa42455",
  measurementId: "G-CEB384LQYX"
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);