import React, { useRef } from 'react';
import { IonItemSliding, IonItem, IonLabel, IonItemOptions, IonItemOption, AlertButton, IonText } from '@ionic/react';
import { Session } from '../models/Schedule';
import { Storage } from '@capacitor/storage';

import firebase from 'firebase/app';
import {firebaseConfig} from '../config/firebase.config';
import 'firebase/firestore';


interface SessionListItemProps {
  session: Session;
  day: string;
  listType: "all" | "favorites";
  onAddFavorite: (id: string) => void;
  onRemoveFavorite: (id: string) => void;
  onShowAlert: (header: string, buttons: AlertButton[]) => void;
  isFavorite: boolean;
}
let counter = 1;
const SessionListItem: React.FC<SessionListItemProps> = ({ isFavorite, onAddFavorite, onRemoveFavorite, onShowAlert, day, session, listType }) => {
  const ionItemSlidingRef = useRef<HTMLIonItemSlidingElement>(null)
  //console.log(day);
  const dismissAlert = () => {
    ionItemSlidingRef.current && ionItemSlidingRef.current.close();
  }
  //console.log(typeof session.id, session.id);
  const idsession = session.id.toString();

  const syncFav = async() => {
    let currentFav = (await Storage.get({ key: 'FAVORITOS' })).value;

    let arrayCurrentFav: Array<string> = [];

    if(currentFav !== null){
      arrayCurrentFav = currentFav!.split(",");
    }
    //console.log(arrayCurrentFav);
    arrayCurrentFav.forEach(element => {
      onAddFavorite(element);
      //console.log(element);
    });
  }
  
  if(counter<=1){
    syncFav();
  }
  counter++;

  const removeFavoriteSession = async() => {

    let currentFav = (await Storage.get({ key: 'FAVORITOS' })).value;

    onAddFavorite(idsession);
    onShowAlert('Ya existe en favoritos', [
      {
        text: 'Cancelar',
        handler: dismissAlert
      },
      {
        text: 'Eliminar',
        handler: async() => {
          
          onAddFavorite(idsession);
          onShowAlert('Sesión agregada a favoritos', [
            {
              text: 'OK',
              handler: dismissAlert
            }
          ]);
          onRemoveFavorite(session.id);

          if(currentFav === null){
            currentFav = "";
          }
          else{
            let separator = ",";
            let values:Array<String> = currentFav.split(separator);
            for(var i = 0 ; i < values.length ; i++) {
              //console.log(values[i]);
              //console.log( session.id , typeof session.id);
              if(values[i] === session.id.toString()) {
                //console.log("encontro a eliminar:"+session.id);
                values.splice(i, 1);
                currentFav = values.join(separator);
              }
            }
          }
          await Storage.set({ key: "FAVORITOS", value: currentFav });
          let userid = (await Storage.get({ key: 'userid' })).value;
          //console.log(userid,session.id);
          const res = await firebase.firestore().collection('favorites').where('userid', '==', userid).where('sessionid', '==', session.id).get();
          //console.log(res);
          
          res.forEach((doc)=>{
            //console.log(doc.id);
            let hecho = firebase.firestore().collection('favorites').doc(doc.id).delete();
            //console.log(hecho);
          });


          //console.log(currentFav);
          dismissAlert();
        }
      }
    ]);
  }

  const addFavoriteSession = async() => {
    if (isFavorite) {
      // woops, they already favorited it! What shall we do!?
      // prompt them to remove it
      removeFavoriteSession();
    } else {
      // remember this session as a user favorite
      let currentFav = (await Storage.get({ key: 'FAVORITOS' })).value;
      if(currentFav === null){
        currentFav = "";
      }
      //console.log(currentFav);
      let userid = (await Storage.get({ key: 'userid' })).value;
      await Storage.set({ key: "FAVORITOS", value: (currentFav+","+session.id).replace(/(^,)|(,$)/g, "") });
      firebase.firestore().collection('favorites').add({
        sessionid: session.id,
        userid: userid
      });
      onAddFavorite(idsession);
      onShowAlert('Sesión agregada a favoritos', [
        {
          text: 'OK',
          handler: dismissAlert
        }
      ]);
    }
  };

  return (
    <IonItemSliding ref={ionItemSlidingRef} class={'track-' + session.tracks[0].toLowerCase()}>
      <IonItem routerLink={`/tabs/schedule/${session.id}`}>
        <IonLabel style={{ whiteSpace: 'normal' }}>
          <h3>{session.name}</h3>
          <p>
            {session.timeStart} &mdash;&nbsp;
            {session.timeEnd} &nbsp;|&nbsp;
            {session.location}<br />
              {session.tracks.map(track => (
                <span style={{backgroundColor:track.split('&&&')[1]}} key={track} className={`track-pill session-track-${track.toLowerCase()}`}>{track.split('&&&')[0]}</span>
              ))}
          </p>
        </IonLabel>
      </IonItem>
      <IonItemOptions>
        {listType === "favorites" ?
          <IonItemOption color="danger" onClick={() => removeFavoriteSession()}>
            Eliminar
          </IonItemOption>
          :
          <IonItemOption color="favorite" onClick={addFavoriteSession}>
            Favorito
          </IonItemOption>
        }
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default React.memo(SessionListItem);