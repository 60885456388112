import React, { useEffect, useState } from 'react';
import { IonHeader, IonToolbar, IonContent, IonSegmentButton, IonSegment, IonPage, IonButtons, IonBackButton, IonButton, IonIcon, IonText, IonList, IonItem, IonLabel, IonListHeader, IonRow, IonCol, IonInput } from '@ionic/react';
import { connect } from '../data/connect';
import { withRouter, RouteComponentProps } from 'react-router';
import * as selectors from '../data/selectors';
import { starOutline, star, calendar, cloudDownload, thumbsUpOutline, thumbsUp, sendOutline } from 'ionicons/icons';
import { addFavorite, removeFavorite } from '../data/sessions/sessions.actions';
import { Session } from '../models/Schedule';
import SpeakerItem from '../components/SpeakerItem';
import { Speaker } from '../models/Speaker';
import { Storage } from '@capacitor/storage';
import { parse, format, isValid, sub } from 'date-fns';
import { es } from 'date-fns/locale'; // Importamos el locale en español para `date-fns`


import firebase from 'firebase/app';
import 'firebase/firestore';

interface OwnProps extends RouteComponentProps { };

export interface Question {
  id?: string;
  title: string;
  user?: string;
  createdAt?: any;
  likesCount: number;
  yaDioLike?: boolean;  // Nuevo campo para saber si el usuario ya dio like
}

interface StateProps {
  session?: Session;
  favoriteSessions: string[],
};

interface DispatchProps {
  addFavorite: typeof addFavorite;
  removeFavorite: typeof removeFavorite;
}

type SessionDetailProps = OwnProps & StateProps & DispatchProps;

const SessionDetail: React.FC<SessionDetailProps> = ({ session, addFavorite, removeFavorite, favoriteSessions }) => {

  const [question, setQuestion] = useState<string>('');
  const [questions, setQuestions] = useState<Question[]>([]);
  let sessionConfirmed = session ? session.id : "";
  let userid = "";
  let contador = 0;
  
  useEffect(() => {
    const loadUserId = async () => {
      userid = (await Storage.get({ key: 'userid' })).value!;
    };
    loadUserId();
  }, []);

  // Consulta inicial para obtener las preguntas junto con la información si el usuario ya dio "like"
  useEffect(() => {
    if (sessionConfirmed) {
      const unsubscribe = firebase.firestore().collection('questions')
        .where('sessionid', '==', sessionConfirmed)
        .orderBy('likesCount', 'desc')
        .onSnapshot(async (snapshot) => {
          const questionsData: Question[] = await Promise.all(
            snapshot.docs.map(async (doc) => {
              const question = doc.data() as Question;
              question.id = doc.id;
              
              // Verificar si el usuario ya dio "like" en la subcolección de 'likes'
              const likeSnapshot = await firebase.firestore()
                .collection('questions')
                .doc(doc.id)
                .collection('likes')
                .where('userId', '==', userid)
                .get();

              question.yaDioLike = !likeSnapshot.empty; // Agregar campo yaDioLike
              return question;
            })
          );
          setQuestions(questionsData);
        });
      return () => unsubscribe();
    }
  }, [sessionConfirmed, userid]);

  // Ejemplo de uso:
  /*const fechaHoraEnEspanol = "Lunes 25 Septiembre 2024T1:00 p.m.";
  const sessionTitle = "Reunión Importante";
  const location = "Auditorio Principal";
  const durationInMinutes = 60;

  try {
    generarYDescargarICS(fechaHoraEnEspanol, sessionTitle, location, durationInMinutes);
  } catch (error) {
    //console.error(error.message);
  }*/
  

  const handleButtonClick = async () => {
    userid = (await Storage.get({ key: 'userid' })).value!;
    await firebase.firestore().collection('questions').add({
      title: question,
      userid: userid,
      sessionid: sessionConfirmed,
      likesCount: 0,
      creado: firebase.firestore.FieldValue.serverTimestamp(),
    });
    setQuestion("");
  };

  const incrementarLikes = async (questionId: string, likesCountActual: number) => {
    userid = (await Storage.get({ key: 'userid' })).value!;
    if (!userid) return;

    const yaDioLike = questions.find((q) => q.id === questionId)?.yaDioLike;
    if (yaDioLike) return;

    try {
      // Incrementar el contador de likes
      await firebase.firestore().collection('questions').doc(questionId).update({
        likesCount: likesCountActual + 1,
      });

      // Añadir el usuario a la subcolección 'likes'
      await firebase.firestore().collection('questions').doc(questionId).collection('likes').add({
        userId: userid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Actualizar el estado local para reflejar el cambio de 'yaDioLike'
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId ? { ...q, likesCount: likesCountActual + 1, yaDioLike: true } : q
        )
      );
    } catch (error) {
      //console.error('Error al incrementar los likes:', error);
    }
  };
  
  //console.log(session);
  if (!session) {
    return <div>Session not found</div>;
  }

 
  const isFavorite = favoriteSessions.indexOf(session.id.toString()) > -1;
  const toggleFavorite = async() => {
    isFavorite ? removeFavorite(session.id) : addFavorite(session.id.toString());

    if(!isFavorite){
      let currentFav = (await Storage.get({ key: 'FAVORITOS' })).value;
      if(currentFav === null){
        currentFav = "";
      }
      //console.log(currentFav);
      let userid = (await Storage.get({ key: 'userid' })).value;
      await Storage.set({ key: "FAVORITOS", value: (currentFav+","+session.id).replace(/(^,)|(,$)/g, "") });
      firebase.firestore().collection('favorites').add({
        sessionid: session.id,
        userid: userid
      });
    }
    else{
      let currentFav = (await Storage.get({ key: 'FAVORITOS' })).value;

      if(currentFav === null){
        currentFav = "";
      }
      else{
        let separator = ",";
        let values:Array<String> = currentFav.split(separator);
        for(var i = 0 ; i < values.length ; i++) {
          //console.log(values[i]);
          //console.log( session.id , typeof session.id);
          if(values[i] === session.id.toString()) {
            //console.log("encontro a eliminar:"+session.id);
            values.splice(i, 1);
            currentFav = values.join(separator);
          }
        }
      }
      await Storage.set({ key: "FAVORITOS", value: currentFav });
      let userid = (await Storage.get({ key: 'userid' })).value;
      //console.log(userid,session.id);
      const res = await firebase.firestore().collection('favorites').where('userid', '==', userid).where('sessionid', '==', session.id).get();
      //console.log(res);
      
      res.forEach((doc)=>{
        //console.log(doc.id);
        let hecho = firebase.firestore().collection('favorites').doc(doc.id).delete();
        //console.log(hecho);
      });
    }
    

  };

  return (
    <IonPage id="session-detail-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/schedule"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => toggleFavorite()}>
              {isFavorite ?
                <IonIcon slot="icon-only" icon={star}></IonIcon> :
                <IonIcon slot="icon-only" icon={starOutline}></IonIcon>
              }
            </IonButton>
            <a href={`https://dev.esimposio.com/miesimposio/appendpoints/generaICS.php?startTime=${encodeURIComponent(convertirFechaICS(session.date+"T"+session.timeStart))}&endTime=${encodeURIComponent(convertirFechaICS(session.date+"T"+session.timeEnd))}&sessionTitle=${encodeURIComponent(session.name)}&location=${encodeURIComponent(session.location)}`}>
              <IonButton>
                <IonIcon slot="icon-only" icon={calendar}
                ></IonIcon>
              </IonButton>
            </a>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding">
          <h1 key={session.name}>{session.name}</h1>
          <IonText color="medium">
            {session.timeStart} &ndash; {session.timeEnd}
            <br />
            {session.location}
            <br /><br />
            {session.speakerNames.map(speaker => (
            <a >{speaker}</a>
            ))}
          </IonText>
        </div>
        <IonRow>
          <IonCol size="10">
            <IonItem fill="outline">
              <IonLabel position="floating">Hacer pregunta</IonLabel>
              <IonInput
                placeholder="Ingrese su pregunta"
                onIonInput={(e) => setQuestion((e.target as HTMLInputElement).value)}
                value={question}
              />
            </IonItem>
          </IonCol>
          <IonCol size="2">
            <IonButton expand="block" onClick={handleButtonClick}>
              <IonIcon icon={sendOutline} slot="icon-only" />
            </IonButton>
          </IonCol>
        </IonRow>
        <IonList>
          <IonListHeader>
            <IonLabel><h1>Preguntas</h1></IonLabel>
          </IonListHeader>
          {questions.map((question) => (
            <IonItem key={question.id}>
              <IonLabel style={{ whiteSpace: 'normal' }}>
                <h2>{question.title}</h2>
                <p>{question.likesCount} Me gusta</p>
              </IonLabel>
              <IonButton
                fill="clear"
                slot="end"
                onClick={() => incrementarLikes(question.id!, question.likesCount)}
                disabled={question.yaDioLike}
              >
                <IonIcon icon={question.yaDioLike ? thumbsUp : thumbsUpOutline} slot="start" />
                {question.yaDioLike ? 'Me gusta' : 'Me gusta'}
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

// Función integrada para convertir fecha/hora de inicio y fin, generar el archivo ICS y permitir la descarga
const convertirFechaICS = (fechaHoraInicio: string) => {
  // Dividimos las cadenas por el separador "T" para obtener fecha y hora
  const [fechaStringInicio, horaStringInicio] = fechaHoraInicio.split("T");

  if (!fechaStringInicio || !horaStringInicio) {
    throw new Error("Formato de fecha y hora inválido");
  }

  // 1. Convertir fecha y hora de inicio en español a un objeto Date
  const fechaInicio = parse(fechaStringInicio, "EEEE dd MMMM yyyy", new Date(), { locale: es });
  if (isNaN(fechaInicio.getTime())) {
    throw new Error("Fecha de inicio no válida");
  }
  let fechaHoraInicioCompleta = parse(horaStringInicio.trim(), "h:mm a", fechaInicio);
  if (isNaN(fechaHoraInicioCompleta.getTime())) {
    throw new Error("Hora de inicio no válida");
  }

  // Restar 5 horas para convertirla a la hora de Panamá (UTC-5)
  fechaHoraInicioCompleta = sub(fechaHoraInicioCompleta, { hours: 5 });

  // 3. Generar el contenido del archivo ICS
  const fechaInicioICS = fechaHoraInicioCompleta.toISOString().replace(/-|:|\.\d\d\d/g, "");

  //console.log(fechaInicioICS);

  return fechaInicioICS;
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    session: selectors.getSession(state, OwnProps),
    favoriteSessions: state.data.favorites
  }),
  mapDispatchToProps: {
    addFavorite,
    removeFavorite
  },
  component: withRouter(SessionDetail)
});
